@keyframes slide-in-from-right {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slide-in-from-left {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

.side-bar {
    position: fixed;
    width: 256px;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    z-index: 1;
    padding: 24px;
    color: black;
    animation: slide-in-from-left 0.3s ease-in-out;
}

.side-bar-header {
    margin: auto;
    color: black;
    font-size: 24px;
    font-weight: bold;
    text-align: left;
}

.side-bar-category-container {
    margin-top: 64px;
}

.side-bar_category {
    margin: 24px 0px;
    list-style: none;
    cursor: pointer;
}

.empty-category-indicator,
.selected-category-indicator {
    background: lightblue;
    width: 4px;
    height: 16px;
    display: inline-block;
    margin-right: 8px;
    transform: translateY(3px);
}

.empty-category-indicator {
    opacity: 0.2;
}

.selected-category-indicator {
    opacity: 1;
}

.side-bar-auth-section {
    position: absolute;
    left: 24px;
    right: 24px;
    bottom: 24px;
}

.side-bar-user-info {
    font-size: 12px;
    text-align: center;
    padding-bottom: 16px;
}

.asking {
    font-size: 16px;
    text-align: center;
    color: black
}

.toolbar {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 380px;
    background-color: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

    align-items: center;
    padding: 32px;
    z-index: 1;
    animation: slide-in-from-right 0.3s ease-in-out;
}

.answer-container {
    white-space: pre-wrap
}

.answer-text {
    min-height: 360px
}

.answer-section {
    margin: 30px 0;
    position: relative;

}

.answer-section .question {
    background-color: #00a7f5;
    padding: 8px;
}


.answer-section .answer {
    background-color: #343536;
    padding: 8px;
}

right-button {
    max-width: 128px;
    display: inline-block
}